<script setup lang="ts">
import type { DropdownItem } from '#ui/types';
import { mountUserAvatarSrc } from '~/frontend/utils';

const user = useUser();
const route = useRoute();
const colorMode = useColorMode();
const i18n = useI18n();
const items = computed<DropdownItem[][]>(() => {
	const colorModeOptions = [];

	if (colorMode.value === 'dark') {
		colorModeOptions.push([
			{
				label: i18n.t('colorMode.lightMode'),
				icon: 'i-ic-baseline-light-mode',
				click() {
					colorMode.preference = 'light';
				},
			},
		]);
	} else if (colorMode.value === 'light') {
		colorModeOptions.push([
			{
				label: i18n.t('colorMode.darkMode'),
				icon: 'i-material-symbols-dark-mode-rounded',
				click() {
					colorMode.preference = 'dark';
				},
			},
		]);
	}

	return [
		[
			{
				label: user.value?.email ?? '',
				slot: 'account',
				disabled: true,
			},
		],

		// [
		//   {
		//     label: "Profile",
		//     icon: "i-heroicons-cog-8-tooth",
		//     to: "/app/profile",
		//   },
		// ],

		...colorModeOptions,

		[
			{
				label: i18n.t('application.signOut'),
				class: 'capitalize',
				icon: 'i-heroicons-arrow-left-on-rectangle',
				external: true,
				to: '/logout',
			},
		],
	];
});
</script>

<template>
	<div v-if="user" class="flex items-center gap-2">
		<NuxtLink v-if="!route.path.startsWith('/app')" to="/app" class="capitalize">{{ $t('application.abbreviation') }} </NuxtLink>

		<span v-else class="text-sm capitalize">{{ user.name }}</span>

		<UDropdown :items="items" :ui="{ item: { disabled: 'cursor-text select-text' } }">
			<UAvatar icon="i-solar-user-bold-duotone" :src="mountUserAvatarSrc(user)" :alt="user.name" />

			<template #account="{ item }">
				<div class="text-left">
					<p class="lowercase">{{ $t('application.signedInWith') }}</p>
					<p class="truncate font-medium">
						{{ item.label }}
					</p>
				</div>
			</template>

			<template #item="{ item }">
				<span class="truncate">{{ item.label }}</span>

				<UIcon :name="item.icon" class="flex-shrink-0 h-4 w-4 ms-auto" />
			</template>
		</UDropdown>
	</div>

	<div v-else>
		<NuxtLink to="/login" class="py-3 px-4 text-center border border-gray-200 dark:border-gray-800 rounded-md block lg:inline lg:border-0">
			Login
		</NuxtLink>
	</div>
</template>
